import classNames from "classnames"
import React from "react"

import type { FAQItemProps } from "./FAQItem"
import { FAQItem } from "./FAQItem"
import type { FAQ } from "./types"

export type FAQListProps = {
  faqs: FAQ[]
  className?: string
} & Pick<FAQItemProps, "onClickItem">

export const FAQList = ({ faqs, onClickItem, className }: FAQListProps) => (
  <ul className={classNames(className, "flex-1 p-0 m-0 list-none")}>
    {faqs.map(({ id, answer, question }, i) => (
      <FAQItem
        key={i}
        id={id}
        position={i + 1}
        answer={answer}
        question={question}
        initialExpand={i === 0}
        onClickItem={onClickItem}
      />
    ))}
  </ul>
)
