import React from "react"

import { useTranslation } from "@bounce/i18n"
import type { Nullable } from "@bounce/util"
import { Button } from "@bounce/web-components"

import { useUrl } from "../../../libs/router"
import { goToApp, useAppURL } from "../../../utils/appURL"

export type FindClosestLocationCtaProps = {
  searchQuery?: Nullable<string>
  from?: string
  title?: string
  anchorTitle?: string
}

export const FindClosestLocationCta = ({ searchQuery, from, title, anchorTitle }: FindClosestLocationCtaProps) => {
  const { t } = useTranslation()
  const buildAppURL = useAppURL()
  const { getUrl } = useUrl()
  const href = buildAppURL(getUrl({ path: "/s" }), { query: searchQuery })

  const onClick = (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    ev.preventDefault()
    const href = ev.currentTarget.href

    return goToApp(href, { cta: "HowItWorksSecondaryLuggageStorage" + from })
  }

  return (
    <div className="mt-20 flex w-full flex-col items-center justify-center">
      <Button
        href={href}
        onClick={onClick}
        rel="nofollow"
        color="primary"
        title={anchorTitle}
        className="w-full sm:w-fit">
        {title ?? t("cmp.howItWorksSecondaryNextLuggageStorage.findClosestLocations", "Find closest locations")}
      </Button>
    </div>
  )
}
