import React from "react"

import { useTranslation } from "@bounce/i18n"
import type { HowItWorksProps as BaseHowItWorksProps } from "@bounce/web-components"
import { HowItWorks as BaseHowItWorks } from "@bounce/web-components"

import type { UseStepsOptions } from "./useSteps"
import { useSteps } from "./useSteps"
import { useSectionViewed } from "../../../hooks/useSectionViewed"
import type { FindClosestLocationCtaProps } from "../FindClosestLocationCta/FindClosestLocationCta"
import { FindClosestLocationCta } from "../FindClosestLocationCta/FindClosestLocationCta"

export type HowItWorksProps = UseStepsOptions &
  Pick<BaseHowItWorksProps, "title" | "titleAs" | "className"> &
  Pick<FindClosestLocationCtaProps, "searchQuery">

export const HowItWorks = ({
  title,
  variant = "app-download",
  searchQuery,
  from,
  titleAs = "h2",
  className,
}: HowItWorksProps) => {
  const { t } = useTranslation()
  const steps = useSteps({ variant, from })
  const { sectionRef } = useSectionViewed({ name: "HowItWorksSecondaryLuggageStorage" })

  return (
    <BaseHowItWorks
      steps={steps}
      title={title ?? t("cmp.howItWorksSecondaryLuggageStorage.titleV5", "How it works")}
      titleAs={titleAs}
      className={className}
      sectionRef={sectionRef}
      children={
        variant === "find-closest-location" && (
          <FindClosestLocationCta
            searchQuery={searchQuery}
            from={from}
            anchorTitle={t("cmp.howItWorksSecondaryLuggageStorage.luggageStorage", "Luggage Storage")}
          />
        )
      }
    />
  )
}
