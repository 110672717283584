import classNames from "classnames"
import React, { useState } from "react"

import type { FAQ } from "./types"

import AddIcon from "@bounce/assets/icons/Add.svg"
import MinusIcon from "@bounce/assets/icons/Minus.svg"

type Item = {
  position: number
} & Pick<FAQ, "id" | "answer" | "question">

export type FAQItemProps = {
  initialExpand?: boolean
  onClickItem?: (item: Item) => void
} & Item

export const FAQItem = ({ id, position, answer, question, onClickItem, initialExpand = false }: FAQItemProps) => {
  const [expand, setExpand] = useState(initialExpand)
  const Icon = expand ? MinusIcon : AddIcon
  const Answer = typeof answer === "string" ? "p" : "div"

  const onClick = () =>
    setExpand(expanded => {
      onClickItem?.({
        id,
        position,
        answer,
        question,
      })

      return !expanded
    })

  return (
    <li className="relative border-b-2 border-b-neutral-300">
      <div className="mx-auto w-full max-w-screen-xl px-6">
        <div className="flex cursor-pointer items-center justify-between py-9 md:py-10" onClick={onClick}>
          <h3 className="subtitle flex max-w-screen-lg flex-1 select-none">{question}</h3>
          <Icon className="ml-12 h-9 w-9 select-none text-neutral-800 content-visibility-auto contain-intrinsic-size-[auto_36px_auto_36px] md:h-12 md:w-12 md:contain-intrinsic-size-[auto_48px_auto_48px]" />
        </div>
        <div
          className={classNames(
            expand ? "max-h-[1000px]" : "max-h-0",
            "max-w-screen-lg overflow-hidden transition-all duration-300",
          )}>
          <Answer className="paragraph1 pb-12 md:pb-14">{answer}</Answer>
        </div>
      </div>
    </li>
  )
}
